import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";

// Pages
import ContentA from '../components/pages/index/ContentA';
import ContentB from '../components/pages/index/ContentB';
import ContentC from '../components/pages/index/ContentC';
import ContentD from '../components/pages/index/ContentD';
import ContentE from '../components/pages/index/ContentE';
// import ContentF from '../components/pages/index/ContentF';
import ContentH from '../components/pages/index/ContentH';
import Footer from '../components/pages/index/Footer';
import Header from '../components/pages/index/Header';
import HeaderIllustration from '../components/pages/index/HeaderIllustration';
import Nav from '../components/pages/index/Nav';

const IndexPage = () => (
    <Layout>
        <SEO title="ruzu" />

        <Nav />

        <div className="globalContent">
            <main>
                <Header />
                <HeaderIllustration />

                <section id="primary">
                    <ContentA />
                    <ContentB />
                    <ContentC />
                    <ContentD />
                    <ContentE />
                    <ContentH />
                </section>
            </main>
        </div>

        <Footer />
    </Layout>
)

export default IndexPage
