import React from "react"
import ruzulogo from './images/logo-new-white.png';

const Nav = () => {
  return (
    <header className="globalNav initialized noDropdownTransition">
      <div className="container-lg">
        <nav>
          <ul className="navRoot">
            <li className="navSection logo">
              <a className="rootLink item-home colorize" href="/" aria-label="Ruzu homepage">
                <img className="ruzu-logo" alt="ruzu logo" src={ruzulogo} width="100%" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Nav;
