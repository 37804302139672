import React from "react"

import './Header.css'

const Header = () => {
    return (
        <header className="">
            <div id="stripes" aria-hidden="true" className="">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <section id="intro" className="">
                <div className="container-lg">
                    <h1>All-in-one marketing workspace</h1>
                    <p className="left-BodyText">
                        Your team spends too many hours on reporting? We've got you. Ruzu brings all your data together in one place. Its marketing software for modern teams.
                    </p>

                    <form method="POST" action="https://ruzu.activehosted.com/proc.php" novalidate>
                        <input type="hidden" name="u" value="1" />
                        <input type="hidden" name="f" value="1" />
                        <input type="hidden" name="s" />
                        <input type="hidden" name="c" value="0" />
                        <input type="hidden" name="m" value="0" />
                        <input type="hidden" name="act" value="sub" />
                        <input type="hidden" name="v" value="2" />

                        <ul className="signUpForm">
                            <li className="emailInput">
                                <input className="TextInput" type="email" name="email" placeholder="Your work email address" required />
                            </li>
                            <li>
                                <button id="_form_1_submit" className="common-Button" type="submit">Request FREE Early Access</button>
                            </li>
                        </ul>
                    </form>
                </div>
            </section>
        </header>
    )
}

export default Header
