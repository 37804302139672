import React from "react"

const ContentA = () => {
    return (
        <section id="complete-toolkit" className="container-lg">
            <section id="section-middle">
                  <h3 class="preHeader-Section">
                  <svg viewBox="0 0 512 512" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="m76 240c12.109375 0 23.109375-4.816406 31.207031-12.625l44.148438 22.074219c-.226563 1.820312-.355469 3.667969-.355469 5.550781 0 24.8125 20.1875 45 45 45s45-20.1875 45-45c0-6.9375-1.582031-13.515625-4.398438-19.390625l60.007813-60.007813c5.875 2.816407 12.453125 4.398438 19.390625 4.398438 24.8125 0 45-20.1875 45-45 0-4.6875-.722656-9.207031-2.058594-13.457031l52.132813-39.097657c7.140625 4.769532 15.710937 7.554688 24.925781 7.554688 24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45c0 4.6875.722656 9.207031 2.058594 13.457031l-52.132813 39.097657c-7.140625-4.769532-15.710937-7.554688-24.925781-7.554688-24.8125 0-45 20.1875-45 45 0 6.9375 1.582031 13.515625 4.398438 19.390625l-60.007813 60.007813c-5.875-2.816407-12.453125-4.398438-19.390625-4.398438-12.109375 0-23.109375 4.816406-31.207031 12.625l-44.148438-22.074219c.226563-1.820312.355469-3.667969.355469-5.550781 0-24.8125-20.1875-45-45-45s-45 20.1875-45 45 20.1875 45 45 45zm360-210c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm-120 90c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm-120 120c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm-120-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0"/><path d="m497 482h-16v-317c0-8.285156-6.714844-15-15-15h-60c-8.285156 0-15 6.714844-15 15v317h-30v-227c0-8.285156-6.714844-15-15-15h-60c-8.285156 0-15 6.714844-15 15v227h-30v-107c0-8.285156-6.714844-15-15-15h-60c-8.285156 0-15 6.714844-15 15v107h-30v-167c0-8.285156-6.714844-15-15-15h-60c-8.285156 0-15 6.714844-15 15v167h-16c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h482c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm-76-302h30v302h-30zm-120 90h30v212h-30zm-120 120h30v92h-30zm-120-60h30v152h-30zm0 0"/></svg>
                    <span class="preHeader-Text">ad performance</span>
                  </h3>

                  <h2 className="common-UppercaseText">
                  Ruzu Is More Than Just a Reporting Tool
                  </h2>

                  <p className="left-BodyText">
                  Ruzu is beautiful and ridiculously easy to use. It connects to the platforms you already use for advertising and automatically collects and processes the data for you.
                  </p>
                    
                  <div style={{width: 'fit-content'}}>
                  <a href="/early-access/sign-up?label=Learn%20More&message=More%20Than%20Reporting%20Tool" rel="nofollow" class="leftLink">
                      Learn More
                    <svg class="crunch-button__arrow-icon"  width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.99979 8.99976L8.99979 4.99976L4.99979 0.999756" stroke="#4bb1fb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M0.999756 4.99976H8.99976" stroke="#4bb1fb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                  </div>  
                </section>
        </section>
    )
}

export default ContentA;
