import React from "react"


const ContentC = () => {
    return (
        <section className="globalFooterCards">
            <div className="container-xl">
                <div className="tile-middle">
                <svg width="100%" viewBox="0 0 770 515">
  <defs>
    <clipPath id="clip-Campaign_View_9">
      <rect width="770" height="515"/>
    </clipPath>
  </defs>
  <g id="Campaign_View_9" data-name="Campaign View – 9" clip-path="url(#clip-Campaign_View_9)">
    <line id="Line_52" data-name="Line 52" y2="84.5" transform="translate(189.5 -0.5)" fill="none" stroke="#ddd" stroke-width="1"/>
    <g id="Group_913" data-name="Group 913" transform="translate(1315 -13184)">
      <g id="Pie_Chart_Percentage_" data-name="Pie Chart (Percentage)" transform="translate(-1315 13184)">
        <rect id="BG" width="771" height="515" fill="#fff"/>
      </g>
      <rect id="Rectangle_1699" data-name="Rectangle 1699" width="110" height="15" rx="7.5" transform="translate(-1288 13210)" fill="#848484"/>
      <rect id="Rectangle_1700" data-name="Rectangle 1700" width="650" height="294" transform="translate(-1255 13350)" fill="#A0A0A0" opacity="0.238"/>
      <g id="Group_912" data-name="Group 912" transform="translate(801 14)">
        <rect id="Rectangle_1534" data-name="Rectangle 1534" width="717" height="0.958" transform="translate(-2089 13384.819)" fill="#eaf0f4"/>
        <rect id="Rectangle_1535" data-name="Rectangle 1535" width="717" height="0.958" transform="translate(-2089 13433.664)" fill="#eaf0f4"/>
        <rect id="Rectangle_1536" data-name="Rectangle 1536" width="717" height="0.958" transform="translate(-2089 13482.509)" fill="#eaf0f4"/>
        <rect id="Rectangle_1537" data-name="Rectangle 1537" width="717" height="0.958" transform="translate(-2089 13531.354)" fill="#eaf0f4"/>
        <rect id="Rectangle_1538" data-name="Rectangle 1538" width="717" height="0.958" transform="translate(-2089 13580.197)" fill="#eaf0f4"/>
        <rect id="Rectangle_1539" data-name="Rectangle 1539" width="717" height="0.958" transform="translate(-2089 13629.042)" fill="#eaf0f4"/>
        <rect id="Rectangle_1540" data-name="Rectangle 1540" width="717" height="0.958" transform="translate(-2089 13336)" fill="#eaf0f4"/>
        <g id="Group_424" data-name="Group 424" transform="translate(-2056 13336.367)">
          <rect id="AprBar" width="93" height="37" transform="translate(557 255.633)" fill="#f68783"/>
          <rect id="MarBar" width="93" height="74" transform="translate(371 218.633)" fill="#f68783"/>
          <rect id="FebBar1" width="93" height="147" transform="translate(186 145.633)" fill="#f68783"/>
          <rect id="JanBar1" width="93" height="293" transform="translate(0 -0.367)" fill="#ff8480"/>
        </g>
        <g id="Group_428" data-name="Group 428" transform="translate(-1933 13467.234)">
          <rect id="Rectangle_1495" data-name="Rectangle 1495" width="38" height="17" rx="8.5" transform="translate(5 -138.235)" fill="#9f9f9f"/>
        </g>
        <g id="Group_429" data-name="Group 429" transform="translate(-1750 13587)">
          <rect id="Rectangle_1495-2" data-name="Rectangle 1495" width="38" height="17" rx="8.5" transform="translate(5 -259)" fill="#9f9f9f"/>
        </g>
        <g id="Group_430" data-name="Group 430" transform="translate(-1564 13605)">
          <rect id="Rectangle_1495-3" data-name="Rectangle 1495" width="38" height="17" rx="8.5" transform="translate(3 -277)" fill="#9f9f9f"/>
        </g>
        <rect id="Rectangle_1557" data-name="Rectangle 1557" width="66" height="14" rx="7" transform="translate(-2043 13642)" fill="#9f9f9f"/>
        <rect id="Rectangle_1558" data-name="Rectangle 1558" width="66" height="14" rx="7" transform="translate(-1856 13642)" fill="#9f9f9f"/>
        <g id="Group_886" data-name="Group 886" transform="translate(-2620 13108)">
          <g id="Group_853" data-name="Group 853" transform="translate(-101 -321.723)">
            <rect id="Rectangle_1562" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
          </g>
          <g id="Group_884" data-name="Group 884" transform="translate(-111 -287.921)">
            <rect id="Rectangle_1562-2" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
          </g>
        </g>
        <rect id="Rectangle_1559" data-name="Rectangle 1559" width="68" height="14" rx="7" transform="translate(-1672 13642)" fill="#9f9f9f"/>
        <g id="Group_888" data-name="Group 888" transform="translate(-2248 13108)">
          <g id="Group_853-2" data-name="Group 853" transform="translate(-101 -321.723)">
            <rect id="Rectangle_1562-3" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
          </g>
          <g id="Group_884-2" data-name="Group 884" transform="translate(-111 -287.921)">
            <rect id="Rectangle_1562-4" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
          </g>
        </g>
        <rect id="Rectangle_1560" data-name="Rectangle 1560" width="68" height="14" rx="7" transform="translate(-1486 13642)" fill="#9f9f9f"/>
        <g id="Group_891" data-name="Group 891" transform="translate(-2062 13108)">
          <g id="Group_853-3" data-name="Group 853" transform="translate(-101 -321.723)">
            <rect id="Rectangle_1562-5" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
          </g>
          <g id="Group_884-3" data-name="Group 884" transform="translate(-111 -287.921)">
            <rect id="Rectangle_1562-6" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
          </g>
        </g>
        <g id="Group_853-4" data-name="Group 853" transform="translate(-2721 12786.277)">
          <rect id="Rectangle_1562-7" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
        </g>
        <g id="Group_884-4" data-name="Group 884" transform="translate(-2731 12820.079)">
          <rect id="Rectangle_1562-8" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
        </g>
        <g id="Group_853-5" data-name="Group 853" transform="translate(-2163 12786.277)">
          <rect id="Rectangle_1562-9" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
        </g>
        <g id="Group_884-5" data-name="Group 884" transform="translate(-2173 12820.079)">
          <rect id="Rectangle_1562-10" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
        </g>
        <g id="Group_853-6" data-name="Group 853" transform="translate(-2535 12786.277)">
          <rect id="Rectangle_1562-11" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
        </g>
        <g id="Group_884-6" data-name="Group 884" transform="translate(-2545 12820.079)">
          <rect id="Rectangle_1562-12" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
        </g>
        <g id="Group_853-7" data-name="Group 853" transform="translate(-2535 12786.277)">
          <rect id="Rectangle_1562-13" data-name="Rectangle 1562" width="66" height="26" rx="10" transform="translate(678 467.723)" fill="#9f9f9f"/>
        </g>
        <g id="Group_884-7" data-name="Group 884" transform="translate(-2545 12820.079)">
          <rect id="Rectangle_1562-14" data-name="Rectangle 1562" width="86" height="14" rx="7" transform="translate(678 467.921)" fill="#f9b069"/>
        </g>
      </g>
    </g>
  </g>
</svg>






                </div>
            </div>
        </section>
    )
}

export default ContentC;
