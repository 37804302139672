import React from "react"

import pic from './images/conversion-funnel1.svg';
import pic2 from './images/media-type.svg';
import pic3 from './images/campaign-spend.svg';
import pic4 from './images/channels-table.svg';
import pic5 from './images/top-channel.svg';
import pic6 from './images/performance.svg';
import pic7 from './images/recent-activity.svg';

const HeaderIllustration = () => {
    return (
        <section id="app-illustrations" className="">
            <div className="tile-small">
                <img alt="campaignspend" src={pic3} width="100%" />
            </div>
            <div className="tile-wide">
                <img alt="conversionfunnel" src={pic} width="100%" />
            </div>
            <div className="tile-small">
                <img alt="budgetmediatype" src={pic2} width="100%" />
            </div>
            <div className="tile-ultrawide">
                <img alt="channels" src={pic4} width="100%" />
            </div>
            <div className="tile-small">
                <img alt="topchannel" src={pic5} width="100%" />
            </div>
            <div className="tile-small">
                <img alt="campaignspend" src={pic7} width="100%" />
            </div>

            <div className="tile-ultrawide">
                <img alt="channels" src={pic6} width="100%" />
            </div>
        </section>
    )
}

export default HeaderIllustration;
